<template>
  <el-dialog
    :width="isCommissionInvoice ? '50%' : '90%'"
    style="text-align: left;"
    title="Select Invoice Items"
    :visible="dialogSelectInvoiceItems"
    @close="handleInvoiceItemsDialogClose"
    @open="handleInvoiceItemsDialogOpen"
  >
    <div>
      <el-table v-if="!isCommissionInvoice" ref="invoiceItemsTable" :data="invoiceItemsTableData" height="48vh" @selection-change="handleSelectChange" :header-cell-style="{ color: '#909399' }" :cell-style="{padding: '0', height: '60px'}">
        <el-table-column
          key="checkbox"
          type="selection"
          min-width="55"
        />
        <el-table-column label="Item Type" min-width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT ? 'Product' : 'Other Cost'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Description" min-width="250px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ scope.row.displayName || scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="invoiceType === INVOICE_TYPE.COMMERCIAL" label="Supply Date" min-width="150px" prop="supplyDate">
          <template v-slot="scope">
            <div>{{ scope.row.supplyDate ? formatDate(new Date(scope.row.supplyDate)) : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Unit" min-width="100px" prop="unitMetric">
          <template v-slot="scope">
            <div>{{ scope.row.unitMetric || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Quantity" min-width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT ? (scope.row.verifiedQuantity ? Number(scope.row.verifiedQuantity).toFixed(3) : (scope.row.finalQuantity ? Number(scope.row.finalQuantity).toFixed(3) : Number(scope.row.maximumQuantity).toFixed(3)) ) : '-'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Price" min-width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{scope.row.itemType === ORDER_ITEM_TYPE.OTHER_COST ? '-' : (invoiceType === INVOICE_TYPE.COMMERCIAL && isSellerView ? numberWithCommas(scope.row.finalBillingPrice, 3) : numberWithCommas(scope.row.price, 3))}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Amount" min-width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="scope.row.itemType === ORDER_ITEM_TYPE.OTHER_COST">{{numberWithCommas(scope.row.cost, 3)}}</div>
            <div v-else>{{invoiceType === INVOICE_TYPE.COMMERCIAL && isSellerView ? numberWithCommas(scope.row.verifiedQuantity * scope.row.finalBillingPrice, 3)
              : (scope.row.finalQuantity ? numberWithCommas(scope.row.finalQuantity * scope.row.price, 3) : numberWithCommas(scope.row.maximumQuantity * scope.row.price, 3))}}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else ref="invoiceItemsTable" :data="invoiceItemsTableData" height="48vh" @selection-change="handleSelectChange" :header-cell-style="{ color: '#909399' }" :cell-style="{padding: '0', height: '60px'}">
        <el-table-column
          key="checkbox"
          type="selection"
          min-width="55"
        />
        <el-table-column label="Fee" min-width="100px" prop="fee">
          <template v-slot="scope">
            <div>{{ numberWithCommas(scope.row.fee, 2) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Fee Currency" min-width="100px" prop="currency">
          <template v-slot="scope">
            <div>{{ scope.row.currency }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Fee UOM Type" min-width="100px" prop="uomType">
          <template v-slot="scope">
            <div>{{ scope.row.uomType }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Amount" min-width="100px" prop="amount">
          <template v-slot="scope">
            <div>{{ numberWithCommas(scope.row.amount, 2) }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="el-dialog__footer">
        <el-button
          outline
          round

          @click="handleInvoiceItemsDialogClose"
        >
          Cancel
        </el-button>
        <el-button
          round

          type="primary"
          @click="handleConfirmInvoiceItemsClick"
          :disabled="invoiceItemsSelected.length === 0"
        >
          Confirm
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { numberWithCommas } from '@/utils'
import { formatDate } from '@/utils/dateTime.js'
import { INVOICE_TYPE, ORDER_ITEM_TYPE, ORDER_PRODUCT_STATUS, ORDER_OTHER_COST_STATUS } from '@/constants.js'

export default {
  name: 'DialogSelectInvoiceItems',
  props: {
    dialogSelectInvoiceItems: Boolean,
    invoiceType: String,
    order: Object,
    selectedItems: Array,
    isSellerView: Boolean,
    propsView: String,
    isCommissionInvoice: Boolean
  },
  data () {
    return {
      INVOICE_TYPE,
      ORDER_ITEM_TYPE,
      invoiceItemsTableData: [],
      invoiceItemsSelected: []
    }
  },
  methods: {
    numberWithCommas,
    formatDate,
    handleSelectChange (selection) {
      this.invoiceItemsSelected = selection
    },
    handleConfirmInvoiceItemsClick () {
      this.$emit('handleConfirmInvoiceItemsClick', this.invoiceItemsSelected)
    },
    handleInvoiceItemsDialogClose () {
      this.invoiceItemsTableData = []
      this.$emit('handleInvoiceItemsDialogClose')
    },
    handleInvoiceItemsDialogOpen () {
      const that = this
      if (!this.isCommissionInvoice) {
        this.getInvoiceItemsByOrder()
      } else {
        this.invoiceItemsTableData = this.order.commissions.filter(c => c.status === 'READY_FOR_INVOICING')
      }
      this.selectedItems.forEach(selectedItem => {
        const item = this.invoiceItemsTableData.find(i => (selectedItem.itemType === ORDER_ITEM_TYPE.PRODUCT && i.id === selectedItem.orderProductId) ||
          (selectedItem.itemType === ORDER_ITEM_TYPE.OTHER_COST && i.id === selectedItem.orderOtherCostId) ||
            (selectedItem.itemType === 'COMMISSION' && i.id === selectedItem.orderCommissionId))
        this.$nextTick().then(() => {
          if (item) {
            that.$refs.invoiceItemsTable.toggleRowSelection(item)
          }
        })
      })
    },
    getInvoiceItemsByOrder () {
      const tempProduct = this.$_.cloneDeep(this.order.products)
      const tempOtherCost = this.$_.cloneDeep(this.order.otherCosts)
      if (this.invoiceType === INVOICE_TYPE.PROFORMA || (!this.isSellerView && this.propsView === 'CREATE_INVOICE')) {
        this.order.products.forEach(p => {
          if (p.status === ORDER_PRODUCT_STATUS.PENDING) {
            this.invoiceItemsTableData.push({ ...p, itemType: ORDER_ITEM_TYPE.PRODUCT })
            const i = tempProduct.findIndex(tp => tp.id === p.id)
            tempProduct.splice(i, 1)
          }
        })
        this.order.otherCosts.forEach(oc => {
          if (oc.status === ORDER_OTHER_COST_STATUS.CONFIRMED) {
            this.invoiceItemsTableData.push({ ...oc, itemType: ORDER_ITEM_TYPE.OTHER_COST })
            const i = tempOtherCost.findIndex(toc => toc.id === oc.id)
            tempOtherCost.splice(i, 1)
          }
        })
      } else {
        this.order.products.forEach(p => {
          if (p.status === ORDER_PRODUCT_STATUS.READY_FOR_INVOICING) {
            this.invoiceItemsTableData.push({ ...p, itemType: ORDER_ITEM_TYPE.PRODUCT })
            const i = tempProduct.findIndex(tp => tp.id === p.id)
            tempProduct.splice(i, 1)
          }
        })
        this.order.otherCosts.forEach(oc => {
          if (oc.status === ORDER_OTHER_COST_STATUS.READY_FOR_INVOICING) {
            this.invoiceItemsTableData.push({ ...oc, itemType: ORDER_ITEM_TYPE.OTHER_COST })
            const i = tempOtherCost.findIndex(toc => toc.id === oc.id)
            tempOtherCost.splice(i, 1)
          }
        })
      }
      if (this.propsView === 'EDIT_INVOICE' && this.invoiceType !== INVOICE_TYPE.PROFORMA) {
        this.selectedItems.forEach(s => {
          if (s.itemType === ORDER_ITEM_TYPE.PRODUCT) {
            const p = tempProduct.find(p => p.id === s.orderProductId)
            if (p) {
              this.invoiceItemsTableData.unshift({ ...p, itemType: ORDER_ITEM_TYPE.PRODUCT })
            }
          } else {
            const oc = tempOtherCost.find(oc => oc.id === s.orderOtherCostId)
            if (oc) {
              this.invoiceItemsTableData.unshift({ ...oc, itemType: ORDER_ITEM_TYPE.OTHER_COST })
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog__footer {
    padding: 10px 10px 0 0;
    height: 100%;
}
</style>
