var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticStyle: { "text-align": "left" },
      attrs: {
        width: _vm.isCommissionInvoice ? "50%" : "90%",
        title: "Select Invoice Items",
        visible: _vm.dialogSelectInvoiceItems,
      },
      on: {
        close: _vm.handleInvoiceItemsDialogClose,
        open: _vm.handleInvoiceItemsDialogOpen,
      },
    },
    [
      _c(
        "div",
        [
          !_vm.isCommissionInvoice
            ? _c(
                "el-table",
                {
                  ref: "invoiceItemsTable",
                  attrs: {
                    data: _vm.invoiceItemsTableData,
                    height: "48vh",
                    "header-cell-style": { color: "#909399" },
                    "cell-style": { padding: "0", height: "60px" },
                  },
                  on: { "selection-change": _vm.handleSelectChange },
                },
                [
                  _c("el-table-column", {
                    key: "checkbox",
                    attrs: { type: "selection", "min-width": "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Item Type",
                      "min-width": "150px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.itemType ===
                                      _vm.ORDER_ITEM_TYPE.PRODUCT
                                      ? "Product"
                                      : "Other Cost"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2125105574
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Description",
                      "min-width": "250px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.displayName ||
                                      scope.row.description
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3454955338
                    ),
                  }),
                  _vm.invoiceType === _vm.INVOICE_TYPE.COMMERCIAL
                    ? _c("el-table-column", {
                        attrs: {
                          label: "Supply Date",
                          "min-width": "150px",
                          prop: "supplyDate",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.supplyDate
                                          ? _vm.formatDate(
                                              new Date(scope.row.supplyDate)
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1649742758
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "Unit",
                      "min-width": "100px",
                      prop: "unitMetric",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.unitMetric || "-")),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      578436798
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Quantity",
                      "min-width": "150px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.itemType ===
                                      _vm.ORDER_ITEM_TYPE.PRODUCT
                                      ? scope.row.verifiedQuantity
                                        ? Number(
                                            scope.row.verifiedQuantity
                                          ).toFixed(3)
                                        : scope.row.finalQuantity
                                        ? Number(
                                            scope.row.finalQuantity
                                          ).toFixed(3)
                                        : Number(
                                            scope.row.maximumQuantity
                                          ).toFixed(3)
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1226824207
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Price",
                      "min-width": "150px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.itemType ===
                                      _vm.ORDER_ITEM_TYPE.OTHER_COST
                                      ? "-"
                                      : _vm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL &&
                                        _vm.isSellerView
                                      ? _vm.numberWithCommas(
                                          scope.row.finalBillingPrice,
                                          3
                                        )
                                      : _vm.numberWithCommas(scope.row.price, 3)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      974883953
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Amount",
                      "min-width": "150px",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.itemType ===
                              _vm.ORDER_ITEM_TYPE.OTHER_COST
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(scope.row.cost, 3)
                                      )
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL &&
                                          _vm.isSellerView
                                          ? _vm.numberWithCommas(
                                              scope.row.verifiedQuantity *
                                                scope.row.finalBillingPrice,
                                              3
                                            )
                                          : scope.row.finalQuantity
                                          ? _vm.numberWithCommas(
                                              scope.row.finalQuantity *
                                                scope.row.price,
                                              3
                                            )
                                          : _vm.numberWithCommas(
                                              scope.row.maximumQuantity *
                                                scope.row.price,
                                              3
                                            )
                                      ) + " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      415511384
                    ),
                  }),
                ],
                1
              )
            : _c(
                "el-table",
                {
                  ref: "invoiceItemsTable",
                  attrs: {
                    data: _vm.invoiceItemsTableData,
                    height: "48vh",
                    "header-cell-style": { color: "#909399" },
                    "cell-style": { padding: "0", height: "60px" },
                  },
                  on: { "selection-change": _vm.handleSelectChange },
                },
                [
                  _c("el-table-column", {
                    key: "checkbox",
                    attrs: { type: "selection", "min-width": "55" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Fee", "min-width": "100px", prop: "fee" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.numberWithCommas(scope.row.fee, 2))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Fee Currency",
                      "min-width": "100px",
                      prop: "currency",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.currency))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Fee UOM Type",
                      "min-width": "100px",
                      prop: "uomType",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.uomType))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Amount",
                      "min-width": "100px",
                      prop: "amount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.numberWithCommas(scope.row.amount, 2)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "el-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { outline: "", round: "" },
                  on: { click: _vm.handleInvoiceItemsDialogClose },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    disabled: _vm.invoiceItemsSelected.length === 0,
                  },
                  on: { click: _vm.handleConfirmInvoiceItemsClick },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }